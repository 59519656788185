import React, { FC, useState, useEffect } from 'react';

import Cookies from 'js-cookie';

import RichText from '@components/RichText/RichText';
import { useContent } from '@sitecore/common';
import { CookieWallRendering } from '@sitecore/types/CookieWall';
import { AlertDialog, TextLink } from '@sparky';

const qubitConsentVersion = '2.0.0';
const privacyConsentVersion = '1';

const CookieWall: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { f } = useContent<CookieWallRendering>();

  const handleSetCookie = (accept: boolean) => {
    const qubitValue = accept ? 'Accepted' : 'Declined';
    const privacyValue = accept ? 'true' : 'false';
    Cookies.set('qubitconsent', qubitValue, { expires: 365 });
    Cookies.set('qubitconsent_version', qubitConsentVersion, { expires: 365 });
    Cookies.set('privacyConsent', privacyValue, { expires: 365 });
    Cookies.set('privacyConsent_type', 'explicit', { expires: 365 });
    Cookies.set('privacyConsent_version', privacyConsentVersion, { expires: 365 });
  };

  useEffect(() => {
    const cookiesExists =
      Cookies.get('qubitconsent') && Cookies.get('privacyConsent') && Cookies.get('privacyConsent_type');
    const cookiesVersionMisMatch =
      Cookies.get('qubitconsent_version') !== qubitConsentVersion ||
      Cookies.get('privacyConsent_version') !== privacyConsentVersion;

    if (!cookiesExists || cookiesVersionMisMatch) {
      setIsOpen(true);
    }
  }, []);

  return (
    <AlertDialog
      confirmText={f('yesButtonText.value')}
      denyText={f('noButtonText.value')}
      disableBackgroundInteraction={false}
      isOpen={isOpen}
      onConfirm={() => handleSetCookie(true)}
      onDeny={() => handleSetCookie(false)}
      setOpen={setIsOpen}
      title={f('title.value')}>
      <RichText
        html={f('description.value')}
        replacements={{
          a: props => <TextLink {...props} />,
        }}
      />
    </AlertDialog>
  );
};

export default CookieWall;
